import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UncdContext } from "./StateProvider";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

type GetStartPropsType = {
    onValidated: () => void;
    onSwitchUncdAndEdob: (isUncd:boolean) => void;
}

const Getstarted: React.FC<GetStartPropsType> = (props) => {
    const ctx = useContext(UncdContext);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        // window.scrollTo(0, 0);
        window.history.pushState('','','/getstarted');
        if (i18n.resolvedLanguage !== window.sessionStorage.getItem('lang')) {
            const lang = window.sessionStorage.getItem('lang')!;
            i18n.changeLanguage(lang);
        }
        document.title = ctx.isUncd ? t("getStartedInfoTitle") : t("getStartedEdobInfoTitle");
        // sample code to dynamically load footer when app start
        if (!document.getElementById("footerElement")) {
            var ifooter = document.createElement('footer-component');
            ifooter.id = "footerElement";
            document.getElementsByTagName('body')[0].appendChild(ifooter);
        }
    });

    const edobAndUncdSwitchHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        ctx.setClaimnumberClaimant('');
        ctx.setfirstnameClaimant('');
        ctx.setlastnameClaimant('');
        ctx.setbirthdayClaimant(null);
        ctx.setaccidentDateClaimant(null);
        ctx.setDocumentUploadQueue([]);
        ctx.setSupportDocumentUploadQueue([]);
        ctx.setnoClaimNumberCheckedClaimant(false);
        ctx.setSeletedAccidentDate('');
        ctx.setSeletedAccidentMonth('');
        ctx.setSeletedAccidentYear('');
        ctx.setSeletedBirthDate('');
        ctx.setSeletedBirthMonth('');
        ctx.setSeletedBirthYear('');
        ctx.setSeletedAccidentMonthNumStr('');
        ctx.setSeletedBirthMonthNumStr('');
        if (event.target.value === 'edob') {
            ctx.setIsUncd(false);
            props.onSwitchUncdAndEdob(false);
        } else {
            ctx.setIsUncd(true);
            props.onSwitchUncdAndEdob(true);
        }
    }

    const onSubmit = (event: React.MouseEvent) => {
        event.preventDefault();
        props.onValidated();
    }

    return (
        <LocalizationProvider dateAdapter={DateFnsAdapter} >
            <Container>
                <Grid container role="region" aria-labelledby="region-info">
                    <Grid item xs={12} className="row">
                        {ctx.isUncd && <div><Typography variant="h1" id="region-info">{t("getStarted") + ' (' + t('getStartUncdStepLabel') + ')'}</Typography></div> }
                        {!ctx.isUncd && <div><Typography variant="h1" id="region-info">{t("getStarted") + ' (' + t('getStartEdobStepLabel') + ')'}</Typography></div> }
                        <br role="presentation"/>
                    </Grid>
                    <Grid item className="row mb-2">
                        <form>
                            <p>{t("claimant-info-mandatory-label")}</p>
                            <br role="presentation" />
                            <FormControl component="fieldset">
                                <FormLabel component="legend" id="uncd-or-edob-radio-buttons-group"><strong>{t("claimUncdOrEdob")}</strong></FormLabel>
                                <RadioGroup
                                    defaultValue="edob"
                                    value={ctx.isUncd ? 'uncd' : 'edob'}
                                    name="edob-uncd-radio-buttons-group"
                                    onChange={edobAndUncdSwitchHandle}
                                >
                                    <FormControlLabel value="edob" control={<Radio />} label={t("edob_label")} />
                                    <FormControlLabel value="uncd" control={<Radio />} label={t("uncd_label")} />
                                </RadioGroup>
                            </FormControl>
                            <div style={{ marginTop: "20px"}}>
                                <button className="btn-wsib" type="submit" aria-label={t("label.next") + t("gotoLabel") + t("step2Label")}
                                    onClick={onSubmit}>{t("label.next")}</button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </LocalizationProvider>
    )
}

export default Getstarted;
