import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CalendarDropdownList.css';
import { dayOptions, monthOptionsEn, monthOptionsFr, yearOptions, monthEnToFr, monthFrToEn } from '../models/data';
import i18next from 'i18next';

  type CalendarPropsType = {
    defaultValue: string | null;
    id: string;
    type: string;
    onChangedDate: (value: string) => void;
  }
  const CalendarDropdownList: React.FC<CalendarPropsType> = (props) => {
    const { i18n } = useTranslation();
    let allOptions = useRef([] as HTMLElement[]);
    let filteredOptions = useRef([] as HTMLElement[]);
    let theFirstOption = useRef(null as HTMLElement | null);
    let theLastOption = useRef(null as HTMLElement | null);
    let filter = useRef('');
    let comboboxHasVisualFocus = useRef(false);
    let listboxHasVisualFocus = useRef(false);
    let hasHover = useRef(false);
    let isBoth = true;
    let option = useRef(null as HTMLElement | null);
    let isOpened = useRef(false);
    const groupClassName = useRef("group");
    const [focusName, setFocusName] = useState("group");
    let listClicked = useRef(false);
    let enterClicked = useRef(false);

    const filterOptions = useCallback(() => {
        let optionTemp = null;
        let currentOption = option.current;
        let filterStr = filter.current.toLowerCase();
        const listboxNode = document.getElementById(props.id + '-listbox');

        filteredOptions.current = [];
        if (listboxNode) {
            listboxNode.innerHTML = '';
        }

        for (let i = 0; i < allOptions.current.length; i++) {
            optionTemp = allOptions.current[i];
            if (
                filterStr?.length === 0 ||
                getLowercaseContent(optionTemp)?.indexOf(filterStr!)! >= 0
            ) {
                filteredOptions.current.push(optionTemp);
                listboxNode?.appendChild(optionTemp);
            }
        }

        // Use populated options array to initialize firstOption and lastOption.
        var numItems = filteredOptions.current.length;
        if (numItems > 0) {
            theFirstOption.current = filteredOptions.current[0];
            theLastOption.current = filteredOptions.current[numItems - 1];

            if (currentOption && filteredOptions.current.indexOf(currentOption) >= 0) {
                optionTemp = currentOption;
            } else {
                optionTemp = theFirstOption.current;
            }
        } else {
            theFirstOption.current = null;
            optionTemp = null;
            theLastOption.current = null;

            if (listboxNode) {
                listboxNode.innerHTML = 'No options';
            }
        }

        return optionTemp;
    }, [props.id]);

    const setValue = useCallback((value: string | null) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (value) {
            filter.current = value;
            comboboxNode.value = filter.current;
            props.onChangedDate(value);
        }
        comboboxNode.setSelectionRange(filter.current.length, filter.current.length);
        filterOptions();
    }, [filterOptions, props]);

    const getAllOptions = useCallback(() => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        const nodes = listboxNode?.getElementsByTagName('li');
        let options:HTMLElement[] = [];
        if (nodes) {
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                options.push(node);
            }
        }
        if (allOptions.current.length === 0) {
            allOptions.current = options;
        }
    },[props.id]);

    const isOpen = useCallback(() => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        return listboxNode?.style.display === 'block';
    }, [props.id]);

    const isClosed= () => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        return listboxNode?.style.display !== 'block';
    }

    const setCurrentOptionStyle = useCallback((optionEle: HTMLElement | null) => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        for (let i = 0; i < filteredOptions.current.length; i++) {
          let opt = filteredOptions.current[i];
          if (opt === optionEle) {
            opt.setAttribute('aria-selected', 'true');
            if (
              listboxNode && listboxNode.scrollTop + listboxNode.offsetHeight <
              opt.offsetTop + opt.offsetHeight
            ) {
              listboxNode.scrollTop =
                opt.offsetTop + opt.offsetHeight - listboxNode.offsetHeight;
            } else if (listboxNode && listboxNode.scrollTop > opt.offsetTop + 2) {
              listboxNode.scrollTop = opt.offsetTop;
            }
          } else {
            opt.setAttribute('aria-selected', 'false');
          }
        }
    }, [props.id]);

    const setActiveDescendant = useCallback((optionEle: HTMLElement | null) => {
        const comboboxNode = document.getElementById(props.id + '-select');
        if (comboboxNode && optionEle && listboxHasVisualFocus.current) {
            comboboxNode.setAttribute('aria-activedescendant', optionEle.id);
            if (!isOptionInView(optionEle)) {
                optionEle.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        } else if (comboboxNode) {
            comboboxNode.setAttribute('aria-activedescendant', '');
        }
    }, [props.id]);

    const removeVisualFocusAll = useCallback(() => {
        const comboboxNode = document.getElementById(props.id + '-select');
        const listboxNode = document.getElementById(props.id + '-listbox');
        if (comboboxNode) {
            setFocusName('group');
        }
        comboboxHasVisualFocus.current = false;
        listboxHasVisualFocus.current = true;
        if (listboxNode) {
            listboxNode.classList.remove('focus');
        }
        option.current = null;
        setActiveDescendant(null);
    },[props.id, setActiveDescendant]);

    const close = useCallback((force: boolean) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        const listboxNode = document.getElementById(props.id + '-listbox');
        const buttonNode = document.getElementById(props.id + '-button');
        if (typeof force !== 'boolean') {
            force = false;
        }

        if (
            comboboxNode && listboxNode && buttonNode
            && (force ||
            (!comboboxHasVisualFocus.current &&
            !listboxHasVisualFocus.current &&
            !hasHover.current))
        ) {
            setCurrentOptionStyle(null);
            listboxNode.style.display = 'none';
            comboboxNode.setAttribute('aria-expanded', 'false');
            buttonNode.setAttribute('aria-expanded', 'false');
            setActiveDescendant(null);
            setFocusName('group focus');
        }
        isOpened.current = false;
        if (enterClicked.current) {
            comboboxNode.focus();
        }
        if (comboboxNode && (!comboboxNode.value || comboboxNode.value === '')) {
            props.onChangedDate('');
        }
        if (comboboxNode && comboboxNode.value && comboboxNode.value !== '') {
            props.onChangedDate(comboboxNode?.value);
        }
    }, [props, setActiveDescendant, setCurrentOptionStyle]);

    useEffect(() => {
        if (i18n.resolvedLanguage !== window.sessionStorage.getItem('lang')) {
            const lang = window.sessionStorage.getItem('lang')!;
            i18n.changeLanguage(lang);
        }
        getAllOptions();
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (comboboxNode.id.indexOf('month') >=0 && comboboxNode.value && comboboxNode.value !== '') {
            if (i18n.resolvedLanguage === 'fr') {
                const birthMonth = monthOptionsFr.map((month) => {
                    return month.value === comboboxNode.value;
                });
                if (birthMonth.indexOf(true) < 0) {
                    const keys = Object.keys(monthEnToFr);
                    keys.map((key) => {
                        if (key === comboboxNode.value) {
                            comboboxNode.value = monthFrToEn[key as keyof typeof monthFrToEn];
                            setValue(monthEnToFr[key as keyof typeof monthEnToFr]);
                        }
                        return key;
                    });
                }
            } else {
                const birthMonth = monthOptionsEn.map((month) => {
                    return month.value === comboboxNode.value;
                });
                if (birthMonth.indexOf(true) < 0) {
                    const keys = Object.keys(monthFrToEn);
                    keys.map((key) => {
                        if (key === comboboxNode.value) {
                            comboboxNode.value = monthEnToFr[key as keyof typeof monthEnToFr];
                            setValue(monthFrToEn[key as keyof typeof monthFrToEn]);
                        }
                        return key;
                    });
                }
            }
        }
        groupClassName.current = focusName;
        const onBackgroundPointerUp = () => {
            if (isOpen() && !hasHover.current) {
                comboboxHasVisualFocus.current = false;
                setCurrentOptionStyle(null);
                removeVisualFocusAll();
                setTimeout(() => {
                    close(true);
                    setFocusName('group');
                }, 200);
            }
        }
        document.body.addEventListener(
            'mouseup',
            onBackgroundPointerUp
        );
        return () => {
            document.body.removeEventListener(
                'mouseup',
                onBackgroundPointerUp
            );
        }
    },[getAllOptions, props.id, focusName, close, i18n, isOpen, removeVisualFocusAll, setCurrentOptionStyle, setValue]);
    
    const getLowercaseContent = (node: HTMLElement) => {
        return node?.textContent?.toLowerCase();
    }

    const isOptionInView = (optionEle: HTMLElement) => {
        const bounding = optionEle.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const setVisualFocusListbox = () => {
        const comboboxNode = document.getElementById(props.id + '-select');
        const listboxNode = document.getElementById(props.id + '-listbox');
        if (comboboxNode) {
            setFocusName('group focus');
        }
        comboboxHasVisualFocus.current = false;
        listboxHasVisualFocus.current = true;
        if (listboxNode) {
            listboxNode.classList.add('focus');
        }
        setActiveDescendant(option.current);
    }

    const setOptionHandler = (optionEle: HTMLElement | null, flag: boolean | null) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (typeof flag !== 'boolean') {
            flag = false;
        }

        if (optionEle) {
            option.current = optionEle;
            setCurrentOptionStyle(option.current);
            setActiveDescendant(option.current);

            if (isBoth && comboboxNode && option.current.textContent) {
            comboboxNode.value = option.current.textContent;
            if (flag) {
                comboboxNode.setSelectionRange(
                option.current.textContent.length,
                option.current.textContent.length
                );
            }
                comboboxNode.setSelectionRange(
                    filter.current.length,
                    option.current.textContent.length
                );
            }
        }
    }

    const setVisualFocusCombobox = () => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (listboxNode && comboboxNode) {
            listboxNode.classList.remove('focus');
            setFocusName('group focus');
            comboboxHasVisualFocus.current = true;
            listboxHasVisualFocus.current = false;
            setActiveDescendant(null);
        }
    }

    const getPreviousOption = (currentOption: HTMLElement | null) => {
        if (currentOption !== theFirstOption.current) {
            if (currentOption) {
                const index = filteredOptions.current.indexOf(currentOption);
                return filteredOptions.current[index - 1];
            }
        }
        return theLastOption.current;
    }

    const getNextOption = (currentOption: HTMLElement | null) => {
        if (currentOption !== theLastOption.current) {
            if (currentOption) {
                let index = filteredOptions.current.indexOf(currentOption);
                return filteredOptions.current[index + 1];
            }
        }
        return theFirstOption.current;
    }

    const hasOptions = () => {
        return filteredOptions.current.length;
    }

    const open = () => {
        const comboboxNode = document.getElementById(props.id + '-select');
        const listboxNode = document.getElementById(props.id + '-listbox');
        const buttonNode = document.getElementById(props.id + '-button');
        if (comboboxNode && listboxNode && buttonNode) {
            listboxNode.style.display = 'block';
            comboboxNode.setAttribute('aria-expanded', 'true');
            buttonNode.setAttribute('aria-expanded', 'true');
        }
        isOpened.current = true;
    }

    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        let flag = false;
        const altKey = event.altKey;

        if (event.ctrlKey || event.shiftKey) {
            return;
        }

        switch (event.code) {
            case 'Enter': {
                if (listboxHasVisualFocus.current) {
                    listClicked.current = true;
                }
                if (option.current?.textContent) {
                    setValue(option.current.textContent);
                }
                close(true);
                setVisualFocusCombobox();
                flag = true;
                break;
            }
            case 'Down':
            case 'ArrowDown': {
                if (filteredOptions.current.length > 0) {
                    if (altKey) {
                        open();
                    } else {
                        open();
                    if (
                        listboxHasVisualFocus.current ||
                        (isBoth && filteredOptions.current.length > 1)
                    ) {
                        setOptionHandler(getNextOption(option.current), true);
                        setVisualFocusListbox();
                    } else {
                        setOptionHandler(theFirstOption.current, true);
                        setVisualFocusListbox();
                    }
                    }
                }
                flag = true;
                break;
            }

            case 'Up':
            case 'ArrowUp': {
                if (hasOptions()) {
                    if (listboxHasVisualFocus.current) {
                        setOptionHandler(getPreviousOption(option.current), true);
                    } else {
                        open();
                        if (!altKey) {
                            setOptionHandler(theLastOption.current, true);
                            setVisualFocusListbox();
                        }
                    }
                }
                flag = true;
                break;
            }
            case 'Esc':
            case 'Escape': {
                if (isOpen()) {
                    close(true);
                    //setFilter(comboboxNode?.value);
                    filter.current = comboboxNode?.value;
                    filterOptions();
                    setVisualFocusCombobox();
                } else {
                    setValue('');
                    comboboxNode.value = '';
                }
                option.current = null;
                flag = true;
                break;
            }
            case 'Tab': {
                /* if (listboxHasVisualFocus.current) {
                    if (option.current) {
                        setValue(option.current?.textContent);
                    }
                }*/
                if (option.current) {
                    setValue(option.current?.textContent);
                }
                close(true);
                break;
            }
            case 'Home': {
                comboboxNode.setSelectionRange(0, 0);
                flag = true;
                break;
            }
            case 'End': {
                var length = comboboxNode.value.length;
                comboboxNode.setSelectionRange(length, length);
                flag = true;
                break;
            }
            default:
            break;
        }

        if (flag) {
            event.stopPropagation();
            if (event.code === 'Enter' && listClicked.current) {
                enterClicked.current = true;
            } else {
                enterClicked.current = false;
            }
            event.preventDefault();
        }
    };

    const isPrintableCharacter = (str: string) => {
        return str.length === 1 && str.match(/\S| /);
    }

    const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        let flag = false;
        let optionTemp = null;
        let char = event.key;

        if (isPrintableCharacter(char)) {
            filter.current = filter.current + char;
        }

        // this is for the case when a selection in the textbox has been deleted
        if (comboboxNode?.value.length < filter.current.length) {
            filter.current = comboboxNode?.value
            option.current = null;
            filterOptions();
        }

        if (event.code === 'Escape' || event.code === 'Esc') {
            return;
        }

        switch (event.code) {
            case 'Backspace': {
                setVisualFocusCombobox();
                setCurrentOptionStyle(null);
                filter.current = comboboxNode?.value;
                getAllOptions();
                const optionStr = filterOptions();
                if (optionStr && isClosed()) {
                    open();
                }
                flag = true;
                break;
            }
            case 'Left':
            case 'ArrowLeft':
            case 'Right':
            case 'ArrowRight':
            case 'Home':
            case 'End': {
                if (isBoth) {
                    filter.current = comboboxNode?.value;
                } else {
                    option.current = null;
                    setCurrentOptionStyle(null);
                }
                setVisualFocusCombobox();
                flag = true;
                break;
            }

            default: {
                if (isPrintableCharacter(char)) {
                    setVisualFocusCombobox();
                    setCurrentOptionStyle(null);
                    flag = true;

                    if (isBoth) {
                        optionTemp = filterOptions();
                        if (isClosed() && comboboxNode?.value.length) {
                            open();
                        }
                    if (optionTemp) {

                        if (
                            getLowercaseContent(optionTemp)?.indexOf(
                                comboboxNode?.value.toLowerCase()
                            ) === 0
                        ) {
                            option.current = optionTemp;
                            if (isBoth || listboxHasVisualFocus.current) {
                                setCurrentOptionStyle(optionTemp);
                                if (isBoth) {
                                    setOptionHandler(optionTemp, null);
                                }
                            }
                        } else {
                            option.current = null;
                            setCurrentOptionStyle(null);
                        }
                    } else {
                        close(false);
                        option.current = null;
                        setActiveDescendant(null);
                    }
                    } else if (comboboxNode?.value.length) {
                        open();
                    }
                }
                break;
            }
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        } else {
            if (event.code === 'Enter' && listClicked.current) {
                listClicked.current = false;
            } else if (event.code === 'Enter') {
                event.preventDefault();
            }
        }
    }

    const onComboboxClick = () => {
        if (isOpen()) {
            close(true);
        } else {
            open();
        }
    }

    const onComboboxBlur = () => {
        removeVisualFocusAll();
    }

    const focusHandler = () => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        filter.current = comboboxNode?.value;
        if (allOptions.current.length === 0) {
            getAllOptions();
        }
        filterOptions();
        setVisualFocusCombobox();
        option.current = null;
        setCurrentOptionStyle(null);
        setFocusName('group focus');
    }

    const onButtonClick = () => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (isOpen()) {
            close(true);
        } else {
            open();
        }
        comboboxNode.focus();
        setVisualFocusCombobox();
    }

    /* Listbox Events */

    const onListboxPointerover = () => {
        hasHover.current = true;
    }

    const onListboxPointerout = () => {
        hasHover.current = false;
        setTimeout(() => close(false), 200);
    }

    // Listbox Option Events

    const onOptionClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        comboboxNode.value = event.currentTarget?.textContent!;

        props.onChangedDate(event.currentTarget?.textContent!);
        
        close(true);
        setFocusName('group focus');
        comboboxNode.focus();
    }

    const onOptionPointerover = () => {
        hasHover.current = true;
        open();
    }

    const onOptionPointerout = () => {
        hasHover.current = false;
        setTimeout(() => close(false), 200);
    }

    const getDateList = () => {
        let dateList = dayOptions.map((day) => {
            return day.value;
        });
        return dateList;
      }
    
      const getMonthEnList = () => {
        const monthList = monthOptionsEn.map((month) => {
            return month.value;
        });
        
        return monthList;
      }
    
      const getMonthFrList = () => {
        const monthList = monthOptionsFr.map((month) => {
            return month.value;
        });
        
        return monthList;
      }

      const getYearList = () => {
        const yearList = yearOptions().map((year) => {
            return year.value;
        });
        
        return yearList;
      }

    return(
        <div className="combobox combobox-list">
            <div className={focusName}>
                <input id={props.id + "-select"} className="cb_edit" type="text"
                    autoComplete='off'
                    role="combobox" aria-autocomplete="list" aria-expanded="false"
                    aria-controls={props.id + "-listbox"}
                    onKeyDown={keyDownHandler} onKeyUp={keyUpHandler}
                    onFocus={focusHandler} onClick={onComboboxClick}
                    onBlur={onComboboxBlur} defaultValue={props?.defaultValue!} />
                <button type="button" id={props.id + "-button"} aria-label="States" aria-expanded="false" aria-controls={props.id + "-listbox"} tabIndex={-1} onClick={onButtonClick}>
                    <svg className="svg_button" width="18" height="16" aria-hidden="true" focusable="false" >
                        <polygon className="arrow" strokeWidth="0" fillOpacity="0.75" fill="currentcolor" points="3,6 15,6 9,14"></polygon>
                    </svg>
                </button>
            </div>
            { props.type === 'day of birth' && <ul className='calendar-list' id={props.id + "-listbox"} role="listbox" aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout} >
                {
                    getDateList().map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
            { props.type === 'month of birth' && i18next.resolvedLanguage === 'en' && <ul className='calendar-list' id={props.id + "-listbox"} role="listbox" z-index={100} aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout} >
                {
                    getMonthEnList().map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
            { props.type === 'month of birth' && i18next.resolvedLanguage === 'fr' && <ul className='calendar-list' id={props.id + "-listbox"} role="listbox" z-index={100} aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout} >
                {
                    getMonthFrList().map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
            { props.type === 'year of birth' && <ul className='calendar-list' id={props.id + "-listbox"} role="listbox" z-index={100} aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout} >
                {
                    getYearList().map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
            { props.type === 'day of injury, illness or exposure' && <ul className='calendar-list' id={props.id + "-listbox"} role="listbox" z-index={100} aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout} >
                {
                    getDateList().map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
            { props.type === 'month of injury, illness or exposure' && i18next.resolvedLanguage === 'en' && <ul className='calendar-list' id={props.id + "-listbox"} role="listbox" z-index={100} aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout} >
                {
                    getMonthEnList().map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
            { props.type === 'month of injury, illness or exposure' && i18next.resolvedLanguage === 'fr' && <ul className='calendar-list' id={props.id + "-listbox"} role="listbox" z-index={100} aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout} >
                {
                    getMonthFrList().map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
            { props.type === 'year of injury, illness or exposure' && <ul className='calendar-list' id={props.id + "-listbox"} role="listbox" z-index={100} aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout} >
                {
                    getYearList().map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
        </div>
    );
}

export default CalendarDropdownList;
