import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { ThemeProvider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./SurveyDialog.css";
import dialogTheme from "../dialogTheme";

const SurveyDialog = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const surveyUrlEn = process.env.REACT_APP_surveyUrlEn?.toString().replace(/{AND}/g,"&");
  const surveyUrlFr = process.env.REACT_APP_surveyUrlFr?.toString().replace(/{AND}/g,"&");
  const surveySessionKey = 'forumSearchSurvey';

  const getLinkUrl = () => {
      return i18n.resolvedLanguage === 'en'
      ? surveyUrlEn
      : surveyUrlFr;
  };

  useEffect(() => {
    showSurvey();
    // eslint-disable-next-line
  },[]);

  const setSurveySession = () => {
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + 30);
    const item = {
      value: new Date().getTime(),
      expiry: expiry.getTime() };
    localStorage.setItem( surveySessionKey, JSON.stringify(item) );
  }

  const getSurveySession = () => {
    const data = localStorage.getItem(surveySessionKey);
    if (!data) {
      return null;
    }
    const item = JSON.parse(data);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(surveySessionKey);
      return null;
    }
    return item.value;
  }


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const declineSurvey = () => {
    handleClose();
  };
  const acceptSurvey = () => {
    handleClose();
  };

  const showSurvey = () => {
    if (!getSurveySession()) {
      setSurveySession();
      handleOpen();
    }
  };


  return (
    <div>
      <ThemeProvider theme={dialogTheme}>
        <Dialog
          open={open}
          onClose={handleClose}
          className={i18n.language === 'fr' ? 'french' : 'english'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              fontFamily: "Arial, sans-serif",
              fontSize: i18n.language === 'fr' ? '13pt' : '14pt',
              color: "white",
              overflowY: "clip"
            },
          }}
        >
          <div
            className="modal-content"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
            aria-label="Tell us about your experience!"
          >
            <DialogTitle id="alert-dialog-title" style={{fontSize: "inherit"}}>
              <div>
                <a
                  href="#!"
                  rel="noopener noreferrer"
                  onClick={declineSurvey}
                  aria-label="Close"
                  id="DistributionPopUpXButtonLink"
                >
                  <div id="DistributionPopUpXButton" onClick={declineSurvey}>
                    &times;
                  </div>
                </a>
                <div className="logo-container">
                  <img
                    src="/assets/b2c/images/wsib_logo_white.svg"
                    alt="Workplace Safety and Insurance Board"
                    id="WSIBLogo"
                  />
                  <img
                    src="/assets/b2c/images/forum_logo_white.svg"
                    alt="Forum Research"
                    id="ForumResearchLogo"
                  />
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
            <Typography component={"h1"} id="DistributionPopUpTitle" style={{fontSize: "inherit"}}>{t("survey.tell_us")}</Typography>
              <div id="DistributionPopUpText">
                <DialogContentText id="alert-dialog-description" style={{fontSize: "inherit"}}>
                  <Typography component={"span"} style={{fontSize: "inherit"}}>
                    {t("survey.thank_you1")}
                  </Typography>
                  <br role="presentation"/>
                  <br role="presentation"/>
                  <Typography component={"span"} style={{fontSize: "inherit"}}>
                    {t("survey.thank_you2")}
                  </Typography>
                </DialogContentText>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="DistributionPopUpBottom">
                <a
                  href={getLinkUrl()}
                  tabIndex={0}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={acceptSurvey}
                  className="DistributionPopUpLink"
                  id="DistributionPopUpYes"
                  aria-label="Yes I'll give feedback (opens in new window)"
                >
                  <div className="DistributionPopUpButton">
                    <Typography component={"span"} style={{fontSize: "inherit"}}>
                      {t("survey.yes_feedback")}
                    </Typography>
                  </div>
                </a>
                <a
                  href="#!"
                  onClick={declineSurvey}
                  className="DistributionPopUpLink"
                  id="DistributionPopUpNo"
                >
                  <div className="DistributionPopUpButton">
                    <Typography component={"span"} style={{fontSize: "inherit"}}>
                      {t("survey.no_feedback")}
                    </Typography>
                  </div>
                </a>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};
export default SurveyDialog;
