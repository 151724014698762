import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography } from '@mui/material';
import { Categories } from '../models/Categories';

type IsCategoryDialogPropsType = {
    isOpen: boolean, 
    dialogContent: {
        categoryList: Categories[],
        title: string,
        lang: string,
        closeButton: string,
    }, 
    handleClose: (event: React.MouseEvent) => void,
};

const IsCategoryDialog: React.FC<IsCategoryDialogPropsType> = (props) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1" align="center" variantMapping={{ h1: 'span' }}>{props.dialogContent.title}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={"div"} id="alert-dialog-description">
                    <Typography component={"div"} variant="body1" align="left">
                        {
                            props.dialogContent!.categoryList.map(category => {
                                let categoryName = category!.categoryNameEn;
                                let categoryDes = category!.categoryDescEn;
                                if (props.dialogContent!.lang! === 'fr') {
                                    categoryName = category!.categoryNameFr;
                                    categoryDes = category!.categoryDescFr;
                                }
                                return (
                                    <div key={categoryName}><div style={{color: '#333333'}}><strong>{categoryName}</strong></div><div style={{color: '#333333'}}>{categoryDes}</div><p></p></div>
                                )
                            })
                        }
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="btn-wsib" type="submit" onClick={props.handleClose}
                style={{ margin: 'auto' }}>
                    {props.dialogContent.closeButton}
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default IsCategoryDialog;