import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography } from '@mui/material';

type IsThisWsibDialogDiloagPropsType = {
    isOpen: boolean, 
    dialogContent: {
        wsibTitle: string,
        wsibContent: string,
        nonWsibTitle: string,
        nonWsibContent: string,
        title: string,
        closeButton: string,
    }, 
    handleClose: (event: React.MouseEvent) => void,
};

const IsThisWsibDialogDiloag: React.FC<IsThisWsibDialogDiloagPropsType> = (props) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1" align="center" variantMapping={{ h1: 'span' }}>{props.dialogContent.title}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={"div"} id="alert-dialog-description">
                    <Typography component={"div"} variant="body1" style={{color: '#333333', marginTop: '10px'}}>
                        <strong>{props.dialogContent.wsibTitle}</strong>
                    </Typography>
                    <Typography component={"div"} variant="body1" style={{color: '#333333'}}>
                        {props.dialogContent.wsibContent}
                    </Typography>
                    <Typography component={"div"} variant="body1" style={{color: '#333333', marginTop: '10px'}}>
                        <strong>{props.dialogContent.nonWsibTitle}</strong>
                    </Typography>
                    <Typography component={"div"} variant="body1" style={{color: '#333333'}}>
                        {props.dialogContent.nonWsibContent}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="btn-wsib" type="submit" onClick={props.handleClose}
                style={{ margin: 'auto' }}>
                    {props.dialogContent.closeButton}
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default IsThisWsibDialogDiloag;