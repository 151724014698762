import { EdobForms } from "./EdobForms";

/**
 * Form list item
 */
export class Categories {
  /**
   * unique form id
   */
  categoryId!: string;
  /**
   * Display name in English
   */
   categoryNameEn!: string
  /**
   * Display name in French
   */
   categoryNameFr!: string
  /**
   * Description in English
   */
   categoryDescEn!: string;
  /**
   * Description in French
   */
   categoryDescFr!: string
  /**
   * Category list order number in English
   */
   categoryDisplayOrderEn!: number;
  /**
   * Category list order number in French
   */
   categoryDisplayOrderFr!: number;
  /**
   * Category sub forms
   */
   forms!: EdobForms[];
}
