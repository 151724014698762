import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography } from '@mui/material';

type UploadProgressDiloagPropsType = {
    isOpen: boolean, 
    dialogContent: {
        content: string,
        title: string,
        closeButton: string,
    }, 
    handleClose: (event: React.MouseEvent) => void,
};

const UploadInProgressDialog: React.FC<UploadProgressDiloagPropsType> = (props) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h3" align="center">{props.dialogContent.title}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant="body1" align="center">
                        {props.dialogContent.content}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="btn-wsib" type="submit" onClick={props.handleClose} 
                style={{ margin: 'auto' }}>
                    {props.dialogContent.closeButton}
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadInProgressDialog;