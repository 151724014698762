import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material'
import { useMsal } from "@azure/msal-react";
import './SessionExpiredDialog.css'
import { AnalyticsLink, LinkKey } from '../services/AnalyticsLink';

type SessionExpiredDialogPropsType = {
    isOpen: boolean,
    handleClose: (event: React.MouseEvent) => void,
};

const SessionExpiredDialog: React.FC<SessionExpiredDialogPropsType> = (props) => {
    const { t, i18n } = useTranslation();
    const { instance } = useMsal();
    const [displayedMinutes, setDisplayedMinutes] = useState('01');
    const [displayedSeconds, setDisplayedSeconds] = useState('00');
    const [loop, setLoop] = useState(0);

    let sessionTimeoutText1 = t("session_timeout_text1");
    let sessionTimeoutText2 = t("session_timeout_text2");
    let timerMinutes = 1;
    let timerSeconds = useRef(600 * timerMinutes);
    let extendButton = t("session_extend_button");
    let leaveButton = t("session_leave_button");
    let title = t("session_timeout_title");
    const analyticsLink = new AnalyticsLink();

    const timerCountdown = () => {
        clearTimer();
        let intervalID = window.setInterval(() => {
            timerSeconds.current -= 1;
            if (timerSeconds.current === 0) {
                clearTimer();
                // google analytics
                analyticsLink.sendLinkAnalyticsReport(LinkKey.timeout, i18n.resolvedLanguage);
                return handleLogout();
            } else {
                const minutes = Math.trunc(timerSeconds.current * timerMinutes / 60);
                setDisplayedMinutes(pad(minutes, 1));
                setDisplayedSeconds(pad(timerSeconds.current - (minutes * 60), 2));
            }
        }, 1000);
        setLoop(intervalID);
    }

    useEffect(() => {
        if (props.isOpen === true) {
            timerCountdown();
        }
        window.history.pushState('','','/sessionexpired');
        // eslint-disable-next-line
    }, [props.isOpen]);

    const handleLogout = () => {
        const logoutUrl = i18n.resolvedLanguage === 'fr' ? 'https://www.wsib.ca/fr' : 'https://www.wsib.ca/';
        const logoutRequest = {
            account: instance.getActiveAccount(),
            postLogoutRedirectUri: logoutUrl

        }
        instance.logoutRedirect(logoutRequest);
    }
    const handleExtend = (event: React.MouseEvent) => {
        event.preventDefault();
        clearTimer();
        props.handleClose(event);
        // google analytics
        analyticsLink.sendLinkAnalyticsReport(LinkKey.extendSession, i18n.resolvedLanguage);
    }

    const handleLeave =  (event: React.MouseEvent) => {
        event.preventDefault();
        clearTimer();
        handleLogout();
        props.handleClose(event);
        // google analytics
        analyticsLink.sendLinkAnalyticsReport(LinkKey.leaveSession, i18n.resolvedLanguage);
    }

    // Clear Timer
    const clearTimer = () => {
        clearInterval(loop);
        timerSeconds.current = 60 * timerMinutes;
        setDisplayedMinutes('01');
        setDisplayedSeconds('00');
    }
    
    // Pad a nuconsole.log('clearTimer');mber with leading zeros
    const pad = (num: number, size: number): string => {
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }

    

    return (
            <Dialog
                    open={props.isOpen}
                    onClose={props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="session-timeout_heading">
                    <Typography variant="h1" align="center" variantMapping={{ h1: 'span' }}>{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography
                            component={"span"} align="center" id="session-timeout_text" style={{ margin: '0', fontSize: '16px', textAlign: 'center' }}
                        >{sessionTimeoutText1}{displayedMinutes} : {displayedSeconds}{sessionTimeoutText2}
                        </Typography>
                    </DialogContentText>    
                </DialogContent>
                <DialogActions>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                        <button className="btn-wsib" type="submit" id="extend_button"
                        onClick={handleExtend}
                        style={{ fontSize: '16px', textAlign: 'center', width:'10em' }}>{extendButton}</button>
                        <button id="session_leave_button"
                        onClick={handleLeave}
                        style={{ fontSize: '16px', textAlign: 'center',margin: '24px 0px 40px 0px' }}>{leaveButton}</button>
                       
                    </div>
                </DialogActions>
                
            </Dialog>
        
    );
}

export default SessionExpiredDialog;