// this implementation is based on angular ng2-file-upload npm package at
// https://github.com/valor-software/ng2-file-upload/tree/development/libs/ng2-file-upload/file-upload

import FileItem from '../models/FileItem';
import { ParsedResponseHeaders } from '../Helper';

class FileUploader {
  url: string;
  method: string;

  constructor(url: string) {
    this.url = url;
    this.method = 'POST';
  }

  uploadItem(fileItem: FileItem) {
    if (fileItem.isUploading) {
      return;
    }
    fileItem.isUploading = true;
    fileItem
      ._onBeforeUpload()
      .then(() => {
        this.xhrTransport(fileItem);
      })
      .catch((err) => {
        fileItem._onError(err.toString(), -1, {});
      });
  }

  cancelItem(fileItem: FileItem) {
    if (fileItem && fileItem.isUploading) {
      fileItem._xhr?.abort();
    }
  }

  xhrTransport(fileItem: FileItem) {
    /** @type {?} */
    let xhr = (fileItem._xhr = new XMLHttpRequest());
    /** @type {?} */
    let sendable;

    if (typeof fileItem._file.size !== 'number') {
      throw new Error('The file specified is no longer valid');
    }

    sendable = new FormData();
    sendable.append(fileItem.alias, fileItem._file, fileItem._file.name);
    if (fileItem.claimId !== '') {
      sendable.append('claimId', fileItem.claimId);
      sendable.append('lastName', fileItem.lastName);
      sendable.append('dateOfBirth', fileItem.dateOfBirth);
    }

    xhr.upload.onprogress = (event) => {
      /** @type {?} */
      let progress = Math.round(
        event.lengthComputable ? (event.loaded * 100) / event.total : 0
      );
      fileItem._onProgress(progress);
    };

    xhr.onload = () => {
      /** @type {?} */
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      /** @type {?} */
      let response = this._transformResponse(xhr.response, headers);
      /** @type {?} */
      if (this._isSuccessCode(xhr.status)) {
        fileItem._onSuccess(response, xhr.status, headers);
      } else {
        fileItem._onError(response, xhr.status, headers);
      }
      fileItem._onComplete(response, xhr.status, headers);
    };

    xhr.onerror = () => {
      /** @type {?} */
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      /** @type {?} */
      let response = this._transformResponse(xhr.response, headers);
      fileItem._onError(response, xhr.status, headers);
      fileItem._onComplete(response, xhr.status, headers);
    };

    xhr.onabort = () => {
      /** @type {?} */
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      /** @type {?} */
      let response = this._transformResponse(xhr.response, headers);
      fileItem._onCancel(response, xhr.status, headers);
      fileItem._onComplete(response, xhr.status, headers);
    };

    xhr.open(this.method, this.url, true);
    if (fileItem.headers.length) {
      for (let header of fileItem.headers) {
        xhr.setRequestHeader(header.name, header.value);
      }
    }

    xhr.send(sendable);
  }

  _parseHeaders(headers: string): ParsedResponseHeaders {
    /** @type {?} */
    let parsed: ParsedResponseHeaders = {};
    /** @type {?} */
    let key;
    /** @type {?} */
    let val;
    /** @type {?} */
    let i;
    if (!headers) {
      return parsed;
    }
    headers.split('\n').forEach((line: string) => {
      i = line.indexOf(':');
      key = line.slice(0, i).trim().toLowerCase();
      val = line.slice(i + 1).trim();
      if (key) {
        parsed[key] = parsed[key] ? parsed[key] + ', ' + val : val;
      }
    });
    return parsed;
  }

  _transformResponse(response: string, headers: ParsedResponseHeaders) {
    return response;
  }

  _isSuccessCode(status: number) {
    return (status >= 200 && status < 300) || status === 304;
  }

  
}

export default FileUploader;
