import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography } from '@mui/material';
import { EdobForms } from '../models/EdobForms';

type IsSubDocumentDialogPropsType = {
    isOpen: boolean, 
    dialogContent: {
        formList: EdobForms[],
        title: string,
        lang: string,
        closeButton: string,
    }, 
    handleClose: (event: React.MouseEvent) => void,
};

const IsSubDocumentDialog: React.FC<IsSubDocumentDialogPropsType> = (props) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1" align="center" variantMapping={{ h1: 'span' }}>{props.dialogContent.title}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={"div"} id="alert-dialog-description">
                    <Typography component={"div"} variant="body1" align="left">
                        {
                            props.dialogContent!.formList.map(form => {
                                let formName = form!.formNameEn;
                                let formDes = form!.descriptionEn;
                                if (props.dialogContent!.lang! === 'fr') {
                                    formName = form!.formNameFr;
                                    formDes = form!.descriptionFr;
                                }
                                return (
                                    <div key={formName}><div style={{color: '#333333'}}><strong>{formName}</strong></div><div style={{color: '#333333'}}>{formDes}</div><p></p></div>
                                )
                            })
                        }
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="btn-wsib" type="submit" onClick={props.handleClose}
                style={{ margin: 'auto' }}>
                    {props.dialogContent.closeButton}
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default IsSubDocumentDialog;