declare global {
  interface Window { dataLayer: any[]; }
}

export enum LinkKey {
  uploadDoc,
  uploadSupport,
  review,
  confirmation,
  uploadAnother, // in confirm page, click upload another document link
  onlineServices, // in confirm page, click goto online service link
  wsib, // in confirm page, user clicks goto wsib page button
  afterClaim, // in confirm page, user clicks learn more button
  timeout, // session timeout dialog shows without any user response
  extendSession, // session timeout dialog shows, user selects extend session
  leaveSession, // session timeout dialog shows, use select leave page button
  technical,  // logged when server error happens
  abandonment,  // logged when user clicks exit button, and also click leave the page button
  edobDocUpload, // for edob uploaddoc
  edobReview, // for edob review page
  edobDeclaration, // for edob review declaration
  edobReviewSubmit, // for edob review submit
  edobConfirm, // for edob confirm page
  edobPrint, // for print/save
  edobUploadAnother, // for edob, in confirm page, click upload another document link
  edobOnlineServices, // for edob, in confirm page, click goto online service link
  edobHome // for edob, return to home
}

// This service is used to implement google analytics tags, please refer to https://jira.wsib.on.ca:8443/browse/UDU-23 for details
export class AnalyticsLink {

  // Fetching up the respctive Link Data after language Check, key must be one of item from link list
  private getLinkAnalyticsReport(lang: string, key: LinkKey): string {
    let linktag = '';
    switch (key) {
        case LinkKey.uploadDoc: {
          if (lang === 'fr') {
            linktag = 'Upload_doc_fr';
          } else {
            linktag = 'Upload_doc';
          }
          break;
        }
        case LinkKey.uploadSupport: {
          if (lang === 'fr') {
            linktag = 'Upload_support_fr';
          } else {
            linktag = 'Upload_support';
          }
          break;
        }
        case LinkKey.review: {
          if (lang === 'fr') {
            linktag = 'Review_fr';
          } else {
            linktag = 'Review';
          }
          break;
        }
        case LinkKey.confirmation: {
          if (lang === 'fr') {
            linktag = 'Confirmation_fr';
          } else {
            linktag = 'Confirmation';
          }
          break;
        }
        case LinkKey.uploadAnother: {
          if (lang === 'fr') {
            linktag = 'Upload_another_fr';
          } else {
            linktag = 'Upload_another';
          }
          break;
        }
        case LinkKey.onlineServices: {
          if (lang === 'fr') {
            linktag = 'OnlineServices_fr';
          } else {
            linktag = 'OnlineServices';
          }
          break;
        }
        case LinkKey.wsib: {
          if (lang === 'fr') {
            linktag = 'WSIB_fr';
          } else {
            linktag = 'WSIB';
          }
          break;
        }
        case LinkKey.afterClaim: {
          if (lang === 'fr') {
            linktag = 'AfterClaim_fr';
          } else {
            linktag = 'AfterClaim';
          }
          break;
        }
        case LinkKey.timeout: {
          if (lang === 'fr') {
            linktag = 'TimeOut_fr';
          } else {
            linktag = 'TimeOut';
          }
          break;
        }
        case LinkKey.extendSession: {
          if (lang === 'fr') {
            linktag = 'ExtendSession_fr';
          } else {
            linktag = 'ExtendSession';
          }
          break;
        }
        case LinkKey.leaveSession: {
          if (lang === 'fr') {
            linktag = 'LeaveSession_fr';
          } else {
            linktag = 'LeaveSession';
          }
          break;
        }
        case LinkKey.technical: {
          if (lang === 'fr') {
            linktag = 'Technical_fr';
          } else {
            linktag = 'Technical';
          }
          break;
        }
        case LinkKey.abandonment: {
          if (lang === 'fr') {
            linktag = 'Abandonment_fr';
          } else {
            linktag = 'Abandonment';
          }
          break;
        }
        case LinkKey.edobDocUpload: {
          if (lang === 'fr') {
            linktag = 'EDOB_UploadDocuments_PageView_Fr';
          } else {
            linktag = 'EDOB_UploadDocuments_PageView';
          }
          break;
        }
        case LinkKey.edobReview: {
          if (lang === 'fr') {
            linktag = 'EDOB_Review_PageView_Fr';
          } else {
            linktag = 'EDOB_Review_PageView';
          }
          break;
        }
        case LinkKey.edobDeclaration: {
          if (lang === 'fr') {
            linktag = 'EDOB_Review_Declaration_Fr';
          } else {
            linktag = 'EDOB_Review_Declaration';
          }
          break;
        }
        case LinkKey.edobReviewSubmit: {
          if (lang === 'fr') {
            linktag = 'EDOB_Review_Submit_Fr';
          } else {
            linktag = 'EDOB_Review_Submit';
          }
          break;
        }
        case LinkKey.edobConfirm: {
          if (lang === 'fr') {
            linktag = 'EDOB_Confirmation_PageView_Fr';
          } else {
            linktag = 'EDOB_Confirmation_PageView';
          }
          break;
        }
        case LinkKey.edobPrint: {
          if (lang === 'fr') {
            linktag = 'EDOB_Confirmation_PrintOrSave_Fr';
          } else {
            linktag = 'EDOB_Confirmation_PrintOrSave';
          }
          break;
        }
        case LinkKey.edobUploadAnother: {
          if (lang === 'fr') {
            linktag = 'EDOB_Confirmation_UploadAnother_Fr';
          } else {
            linktag = 'EDOB_Confirmation_UploadAnother';
          }
          break;
        }
        case LinkKey.edobOnlineServices: {
          if (lang === 'fr') {
            linktag = 'EDOB_Confirmation_OtherOnlineServices_Fr';
          } else {
            linktag = 'EDOB_Confirmation_OtherOnlineServices';
          }
          break;
        }
        case LinkKey.edobHome: {
          if (lang === 'fr') {
            linktag = 'EDOB_Confirmation_ReturnToHomepage_Fr';
          } else {
            linktag = 'EDOB_Confirmation_ReturnToHomepage';
          }
          break;
        }
        default: {
          console.log('Unknow link tag for google analytics:' + key);
        }
      }
    return linktag;
  }

  /* Pushing the link Data to the google analytics */
  sendLinkAnalyticsReport(key: LinkKey, lang: string) {
    const currentLaunguageBrowser = lang;
    const AnalyticsLinkS = this.getLinkAnalyticsReport(
      currentLaunguageBrowser,
      key
    );

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'event': AnalyticsLinkS});

    console.log('googld analytics logs: ', window.dataLayer);
  }
}
