/**
 * Form list item
 */
export class EdobForms {
  /**
   * unique form id
   */
  formId!: string;
  /**
   * Display name in English
   */
  formNameEn!: string;
  /**
   * Display name in French
   */
  formNameFr!: string;
  /**
   * not used by unnumbered project
   */
  isWsibForm!: boolean;
  /**
   * only apply to supporting document item to indicate it belongs to which main form id
   */
  mainFormId!: string;
  /**
   * Form list order number in English
   */
   ibfEnDisplayOrder!: number;
  /**
   * Form list order number in French
   */
   ibfFrDisplayOrder!: number;
  /**
   * Description in English
   */
   descriptionEn!: string;
  /**
   * Description in French
   */
   descriptionFr!: string

}
