export interface DataOption {
    readonly value: string;
    readonly label: string;
}

export const dayOptions: readonly DataOption[] = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' }
  ];
export const monthOptionsEn: readonly DataOption[] = [
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' }
  ];
export const monthOptionsFr: readonly DataOption[]= [
    { value: 'janvier', label: 'janvier' },
    { value: 'février', label: 'février' },
    { value: 'mars', label: 'mars' },
    { value: 'avril', label: 'avril' },
    { value: 'mai', label: 'mai' },
    { value: 'juin', label: 'juin' },
    { value: 'juillet', label: 'juillet' },
    { value: 'août', label: 'août' },
    { value: 'septembre', label: 'septembre' },
    { value: 'octobre', label: 'octobre' },
    { value: 'novembre', label: 'novembre' },
    { value: 'décembre', label: 'décembre' }
  ];

  export const monthEnToFr = {
    'January': 'janvier',
    'February': 'février',
    'March': 'mars',
    'April': 'avril',
    'May': 'mai',
    'June': 'juin',
    'July': 'juillet',
    'August': 'août',
    'September': 'septembre',
    'October': 'octobre',
    'November': 'novembre',
    'December': 'décembre'
};

  export const monthFrToEn = {
    'janvier': 'January',
    'février': 'February',
    'mars': 'March',
    'avril': 'April',
    'mai': 'May',
    'juin': 'June',
    'juillet': 'July',
    'août': 'August',
    'septembre': 'September',
    'octobre': 'October',
    'novembre': 'November',
    'décembre': 'December'
  };

export const monthToNumberStrEn = {
    'January': '01',
    'February': '02',
    'March': '03',
    'April': '04',
    'May': '05',
    'June': '06',
    'July': '07',
    'August': '08',
    'September': '09',
    'October': '10',
    'November': '11',
    'December': '12'
  }
export const monthToNumberStrFr = {
    'janvier': '01',
    'février': '02',
    'mars': '03',
    'avril': '04',
    'mai': '05',
    'juin': '06',
    'juillet': '07',
    'août': '08',
    'septembre': '09',
    'octobre': '10',
    'novembre': '11',
    'décembre': '12'
  }

export const yearOptions = () => {
    let  yearItems:DataOption[] = [];
    let yearList = [];

    for (let i = 1900; i <= (new Date()).getFullYear(); i++) {
        yearList.push(i);
    }

    yearItems = yearList.map((year) => {
        return {'value': year?.toString(), 'label': year?.toString()}
    });

    return yearItems;
};
