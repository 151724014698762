import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography } from '@mui/material';

type FileTypeInfoDiloagPropsType = {
    isOpen: boolean, 
    dialogContent: {
        typeList: string,
        title: string,
        closeButton: string,
    }, 
    handleClose: (event: React.MouseEvent) => void,
};

const FileTypeInfoDialog: React.FC<FileTypeInfoDiloagPropsType> = (props) => {
    const typeList = props.dialogContent.typeList.split(';');
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="file-type-dialog"
            aria-describedby="file-type-dialog"
        >
            <DialogTitle id="file-type-dialog-title">
                <Typography variant="h1" align="center" variantMapping={{ h1: 'span' }}>{props.dialogContent.title}</Typography>
            </DialogTitle>
            <DialogContent style={{ overflowY: 'unset'}}>
                <DialogContentText component={"div"} id="file-type-dialog-content">
                    <Typography component={"div"} variant="body1" align="center">
                        {
                            typeList.map(docType => {
                                return (
                                    <div key={docType} style={{ backgroundColor: '#FFFFFF', color: '#333333' }}>{docType}</div>
                                )
                            })
                        }
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="btn-wsib" type="submit" onClick={props.handleClose}
                style={{ margin: 'auto' }}>
                    {props.dialogContent.closeButton}
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default FileTypeInfoDialog;