import React, { useContext, useEffect } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { UncdContext } from "./StateProvider"
import PrintIcon from '@mui/icons-material/Print';
import { useMsal } from "@azure/msal-react";
import Print from './Print';
import SurveyDialog from '../dialogs/SurveyDialog';
import { AnalyticsLink, LinkKey } from '../services/AnalyticsLink';
import { Link } from '@mui/material';
import './Confirmation.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

type ClaimantInfoPropsType = {
    onPrev: () => void;
    onValidated: () => void;
    onConfirmaton: () => void;
  }
//TODO
const Confirmation: React.FC<ClaimantInfoPropsType> = (props) => {
    const { t, i18n } = useTranslation();
    const ctx = useContext(UncdContext);
    const { instance } = useMsal();
    const analyticsLink = new AnalyticsLink();

    //google analytics
    if (ctx.isUncd) {
        analyticsLink.sendLinkAnalyticsReport(LinkKey.confirmation, i18n.resolvedLanguage);
    } else {
        analyticsLink.sendLinkAnalyticsReport(LinkKey.edobConfirm, i18n.resolvedLanguage);
    }

    const onPrint = () => {
        if (!ctx.isUncd) {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.edobPrint, i18n.resolvedLanguage);
        }
        window.print();
    }

    const gotoAppStartPage = () => {
        if (i18n.resolvedLanguage === 'fr') {
            window.location.href = `${window.location.href}?lang=fr`
        } else {
            window.location.reload();
        }
        if (ctx.isUncd) {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.uploadAnother, i18n.resolvedLanguage);
        } else {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.edobUploadAnother, i18n.resolvedLanguage);
        }
    }

    const returnToHomePage = () => {
        const logoutUrl = i18n.resolvedLanguage === 'fr' ? 'https://www.wsib.ca/fr' : 'https://www.wsib.ca/';
        const logoutRequest = {
            account: instance.getActiveAccount(),
            postLogoutRedirectUri: logoutUrl

        }
        instance.logoutRedirect(logoutRequest);
        // google analytics
        if (ctx.isUncd) {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.wsib, i18n.resolvedLanguage);
        } else {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.edobHome, i18n.resolvedLanguage);
        }
    };

    const gotoOnlineService = () => {
        const logoutUrl = i18n.resolvedLanguage === 'fr' ? 'https://www.wsib.ca/fr/servicesenligne' : 'https://www.wsib.ca/en/onlineservices';
        const logoutRequest = {
            account: instance.getActiveAccount(),
            postLogoutRedirectUri: logoutUrl

        }
        instance.logoutRedirect(logoutRequest);
         // google analytics
        if (ctx.isUncd) {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.onlineServices, i18n.resolvedLanguage);
        } else {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.edobOnlineServices, i18n.resolvedLanguage);
        }
    };

    const gotoLearnMore = () => {
        const logoutUrl = i18n.resolvedLanguage === 'fr' ? 'https://www.wsib.ca/fr/votre-guide-prestations-services-et-responsabilites' : 'https://www.wsib.ca/en/your-guide-benefits-services-and-responsibilities';
        const logoutRequest = {
            account: instance.getActiveAccount(),
            postLogoutRedirectUri: logoutUrl

        }
        instance.logoutRedirect(logoutRequest);
         // google analytics
        analyticsLink.sendLinkAnalyticsReport(LinkKey.afterClaim, i18n.resolvedLanguage);
    }


    useEffect(() => {
        props.onConfirmaton();
        window.scrollTo(0, 0);
        window.history.pushState('','','/confirmation');
        document.title = ctx.isUncd ? t("confirmationTitle") : t("edobConfirmationTitle");
        // eslint-disable-next-line
    }, [t]);

    return (
        <LocalizationProvider dateAdapter={DateFnsAdapter} >
            <Container>
                <Grid container role="region" rowSpacing={1} aria-labelledby="region-confirmation">

                    <Grid item xs={12} className="row">
                        {ctx.isUncd && <div><Typography variant="h1" id="region-confirmation">{t("confirmation") + ' (' + t('uncdCofirmStepLabel') + ')'}</Typography></div>}
                        {!ctx.isUncd && <div><Typography variant="h1" id="region-confirmation">{t("confirmation") + ' (' + t('edobConfirmStepLabel') + ')'}</Typography></div>}
                        {!ctx.isUncd && <div><Typography variant="h2"  style={{marginTop: 10}}>{t("label.claim.number")}{ctx.claimnumberClaimant}</Typography></div>}
                        <br role="presentation"/>
                    </Grid>
                    <Grid item xs={1} className='verticalLine'></Grid>
                    <Grid item xs={1} className="fileItemContainer">
                        <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                    </Grid>              
                    <Grid item xs={9} className="fileItemContainer">            
                        {ctx.isUncd ? t('message.success.thank.you') : t('message.edob.confirm')}
                        {ctx.sendConfirmationEmail &&
                        <div style={{marginTop: 10}}>{t('message.success.email')}</div>}
                        <div style={{marginTop: 10}}>{t('message.response.review')}</div>
                    </Grid>
                    <Grid item xs={12} className="row">
                        <div>
                            <br role="presentation"/>
                            <button className="link" onClick={onPrint}  >
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <PrintIcon style={{ marginRight: '5px' }} />
                                    <span>{t("print.link")}</span></span>
                            </button>

                        </div>
                    </Grid>

                    {!ctx.isUncd && <Grid item xs={12} className="row" style={{ marginTop: "14px" }}>
                        <div>{t("message.addClaim1")}</div>
                    </Grid>}

                    {!ctx.isUncd && <Grid item xs={12} className="row">
                        <div>{t('message.addClaim2')}<Link className={'link'} underline='hover' href={t("message.addClaim.link1")}
                                target="_blank" rel="noopener noreferrer">{t("message.addClaim.link1.str")}</Link>
                            {t("message.addClaim4")}</div>
                    </Grid>}

                    {!ctx.isUncd && <Grid item xs={12} className="row">
                        <div>{t('message.addClaim3')}<Link className={'link'} underline='hover' href={t("message.addClaim.link2")}
                                target="_blank" rel="noopener noreferrer">{t("message.addClaim.link2.str")}</Link>
                            {t("message.addClaim4")}</div>
                    </Grid>}

                    <Grid item xs={12} className="row">
                        <div>
                            <br role="presentation"/>
                            <Link className={'link'} underline="hover" onClick={gotoAppStartPage}>{ctx.isUncd ? t("upload.another") : t("edob.upload.another")}</Link>.
                        </div>
                    </Grid>
                    <Grid item xs={12} className="row">
                        <div>
                            <Link className={'link'} underline='hover' onClick={gotoOnlineService}>{t("checkout.online.service")}</Link>.
                        </div>
                    </Grid>
                    {ctx.isUncd && <Grid item xs={12} className="row">
                        <div>
                            <Link className={'link'} underline='hover' onClick={gotoLearnMore}>{t("learnabout")}</Link>.
                        </div>
                    </Grid>}
                    <Grid item xs={12} className="row">
                        <div>
                            <Link className={'link'} underline='hover' onClick={returnToHomePage}>{t("message.return.to.wsib.home")}</Link>.
                        </div>
                    </Grid>
                </Grid>

            </Container>
            <Print></Print>
            <SurveyDialog></SurveyDialog>
        </LocalizationProvider >
    );
}

export default Confirmation;
