import { createTheme } from '@mui/material/styles';

const dialogTheme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: '0px'
                }
            }
        },
   MuiDialogTitle: {
        styleOverrides: {
            root: {
                padding: '0px',
                fontsize: '14pt'
            }
        }
    },
    MuiTypography: {
          styleOverrides: {
              root: {
                  fontFamily: 'Arial, sans-serif',
                  margin: '0px',
                  padding: '0px',
                  fontsize:'1.2rem'
              }
          }
      },
      MuiDialogContentText: {
          styleOverrides: {
              root: {
                  fontsize: '1.2rem',
                  color: 'white'
              }
          }
      }
    },
  });

  export default dialogTheme;