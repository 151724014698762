import { loginRequest } from './AuthConfig';
import {
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { Forms } from './models/Forms';
import FileItem from './models/FileItem';
import jwt_decode from "jwt-decode";
import { Categories } from './models/Categories';
import { EdobForms } from './models/EdobForms';

export type StepComponentProps = {
  onPrev: () => void;
  onValidated: () => void;
};

export type ParsedResponseHeaders = { [headerFieldName: string]: string };

export type FailedFileListsType = {
  serverError: boolean;
  fileWrongSizeList: string[];
  fileWrongTypeList: string[];
  filenameInvalidList: string[];
  fileVirusList: string[];
  maxFileNumReached: boolean;
};

export type FileConfirmType = {
  fileId: string;
  formId: string;
  wsibform: boolean;
  formNameEn: string;
  formNameFr: string;
};

const getConfirmFileList = (fileList: FileItem[], isWsib: boolean, isUncd: boolean): FileConfirmType[] => {
  const confirmList: FileConfirmType[] = [];
  for (const item of fileList) {
    let formId = item.wsibForm?.formId!;
    let formNameEn = item.wsibForm?.formNameEn!;
    let formNameFr = item.wsibForm?.formNameFr!;
    if (!isUncd) {
      if (!item.category) {
        formId = item.edobForm?.formId!;
        formNameEn = item.edobForm?.formNameEn!;
        formNameFr = item.edobForm?.formNameFr!;
      } else {
        formId = item.subEdobForm?.formId!;
        formNameEn = item.subEdobForm?.formNameEn!
        formNameFr = item.subEdobForm?.formNameFr!;
      }
    }
    const confirmItem: FileConfirmType = {
      fileId: item.alias,
      formId: formId!,
      wsibform: isWsib,
      formNameEn: formNameEn,
      formNameFr: formNameFr
    };
    confirmList.push(confirmItem);
  }
  return confirmList;
};

/**
   * function to decode the token and get Oid
   */
 const retrieveOid = (jwtToken: string) => {
    let oid = '';
    try {
        const decodedToken:any = jwt_decode(jwtToken);
        if (
          decodedToken &&
          decodedToken.oid !== undefined &&
          decodedToken.oid !== null
        ) {
          oid = decodedToken.oid;
        }
      } catch (err) {
        console.log('invalid jwt token format. ' + err);
        //this.homeService.applicationInsightsService.logException(err);
      }
    return oid;
  }

  const getFormatDate = (date : Date) => {
      var day = ('0' + date.getDate()).slice(-2);
      var month = ('0' + (date.getMonth() + 1)).slice(-2);
      var year = date.getFullYear();
    
      return day + '/' + month + '/' + year;
  }

const isOnMobileBrowser = () => {
  return /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  );
};

const getMainFormNameList = (lang: string, formList: Forms[]): string[] => {
    const forms = formList.filter((s) => s.mainFormId === '0');
    forms.sort((a, b) => {
      if (lang === 'en') {
        return a.ibfEnDisplayOrder - b.ibfEnDisplayOrder;
      } else {
        return a.ibfFrDisplayOrder - b.ibfFrDisplayOrder;
      }
    });
    const nameList = forms.map((form) => {
        if (lang === 'en') {
            return form.formNameEn.replaceAll('’', '\'');
        } else {
            return form.formNameFr.replaceAll('’', '\'');
        }
    })
    return nameList;
};

const getMainFormList = (lang: string, formList: Forms[]): Forms[] => {
  const forms = formList.filter((s) => s.mainFormId === '0');
  forms.sort((a, b) => {
    if (lang === 'en') {
      return a.ibfEnDisplayOrder - b.ibfEnDisplayOrder;
    } else {
      return a.ibfFrDisplayOrder - b.ibfFrDisplayOrder;
    }
  });
  return forms;
};

const getWsibFormNameList = (lang: string, formList: EdobForms[]): string[] => {
    formList.sort((a, b) => {
      if (lang === 'en') {
        return a.ibfEnDisplayOrder - b.ibfEnDisplayOrder;
      } else {
        return a.ibfFrDisplayOrder - b.ibfFrDisplayOrder;
      }
    });
    const nameList = formList.map((form) => {
        if (lang === 'en') {
            return form.formNameEn.replaceAll('’', '\'');
        } else {
            return form.formNameFr.replaceAll('’', '\'');
        }
    })
    return nameList;
};

const getWsibFormList = (lang: string, formList: EdobForms[]): EdobForms[] => {
  formList.sort((a, b) => {
    if (lang === 'en') {
      return a.ibfEnDisplayOrder - b.ibfEnDisplayOrder;
    } else {
      return a.ibfFrDisplayOrder - b.ibfFrDisplayOrder;
    }
  });
  return formList;
};

const getNoWsibFormNameList = (lang: string, formList: EdobForms[]): string[] => {
    formList.sort((a, b) => {
      if (lang === 'en') {
        return a.ibfEnDisplayOrder - b.ibfEnDisplayOrder;
      } else {
        return a.ibfFrDisplayOrder - b.ibfFrDisplayOrder;
      }
    });
    const nameList = formList.map((form) => {
        if (lang === 'en') {
            return form.formNameEn.replaceAll('’', '\'');
        } else {
            return form.formNameFr.replaceAll('’', '\'');
        }
    })
    return nameList;
};

const getNoWsibFormList = (lang: string, formList: EdobForms[]): EdobForms[] => {
  formList.sort((a, b) => {
    if (lang === 'en') {
      return a.ibfEnDisplayOrder - b.ibfEnDisplayOrder;
    } else {
      return a.ibfFrDisplayOrder - b.ibfFrDisplayOrder;
    }
  });
  return formList;
};

const getCategoriesNameList = (lang: string, categoryList: Categories[]): string[] => {
    categoryList.sort((a, b) => {
      if (lang === 'en') {
        return a.categoryDisplayOrderEn - b.categoryDisplayOrderEn;
      } else {
        return a.categoryDisplayOrderFr - b.categoryDisplayOrderFr;
      }
    });
    const nameList = categoryList.map((category) => {
        if (lang === 'en') {
            return category.categoryNameEn.replaceAll('’', '\'');
        } else {
            return category.categoryNameFr.replaceAll('’', '\'');
        }
    })
    return nameList;
};

const getCategoriesList = (lang: string, categoryList: Categories[]): Categories[] => {
  const categories = categoryList;
  categories.sort((a, b) => {
    if (lang === 'en') {
      return a.categoryDisplayOrderEn - b.categoryDisplayOrderEn;
    } else {
      return a.categoryDisplayOrderFr - b.categoryDisplayOrderFr;
    }
  });
  return categories;
};

const getSupportingFormNameList = (
    lang: string,
    formList: Forms[],
    mainDocItem: FileItem
  ): string[] => {
    const supportForms = formList.filter(
      (s) => s.mainFormId === mainDocItem.wsibForm!.formId
    );
    supportForms.sort((a, b) => {
      if (lang === 'en') {
        return a.ibfEnDisplayOrder - b.ibfEnDisplayOrder;
      } else {
        return a.ibfFrDisplayOrder - b.ibfFrDisplayOrder;
      }
    });

    const nameList = supportForms.map((form) => {
        if (lang === 'en') {
            return form.formNameEn.replaceAll('’', '\'');
        } else {
            return form.formNameFr.replaceAll('’', '\'');
        }
    })
    return nameList;
};

const getSupportingFormList = (
  lang: string,
  formList: Forms[],
  mainDocItem: FileItem
): Forms[] => {
  const supportForms = formList.filter(
    (s) => s.mainFormId === mainDocItem.wsibForm!.formId
  );
  supportForms.sort((a, b) => {
    if (lang === 'en') {
      return a.ibfEnDisplayOrder - b.ibfEnDisplayOrder;
    } else {
      return a.ibfFrDisplayOrder - b.ibfFrDisplayOrder;
    }
  });
  return supportForms;
};

const getToken : (instance: IPublicClientApplication)=> Promise<string> = async (instance: IPublicClientApplication) => {
  const account = instance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const accessTokenRequest = {
    ...loginRequest,
    account: account,
  };

  try {
    const response = await instance.acquireTokenSilent(accessTokenRequest);
    return response.accessToken;
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      console.log('Fail to get token, let user login and reload the app');
      instance.acquireTokenRedirect(accessTokenRequest);
      return "";
    } else {
      throw err;
    }
    
  }
};

export {
  isOnMobileBrowser,
  getMainFormList,
  getSupportingFormList,
  getToken,
  getConfirmFileList,
  retrieveOid,
  getFormatDate,
  getWsibFormList,
  getCategoriesList,
  getNoWsibFormList,
  getMainFormNameList,
  getWsibFormNameList,
  getCategoriesNameList,
  getNoWsibFormNameList,
  getSupportingFormNameList
};
