import React, { useState, useEffect, useRef, useCallback } from 'react';
import  { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { useMsal, useMsalAuthentication, AuthenticatedTemplate } from "@azure/msal-react";
import wsibTheme from './wsibTheme';
import './App.css';
import { EventType, InteractionType, AccountInfo } from '@azure/msal-browser';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StateProvider } from './components/StateProvider'
import ClaimantInfo from './components/ClaimantInfo';
import DocUpload from './components/DocUpload';
import SupportDocUpload from './components/SupportDocUpload';
import Review from './components/Review';
import Confirmation from './components/Confirmation';
import SessionExpiredDialog from './dialogs/SessionExpiredDialog';
import NavigationAwayDialog from './dialogs/NavigationAwayDialog';
import { AnalyticsLink, LinkKey } from './services/AnalyticsLink';
import { loginRequest } from './AuthConfig';
import Getstarted from './components/Getstarted';
import ResetPassword from './components/ResetPassword';

function App() {
  const [activeStep, setActiveStep] = useState(0);
  const [sessionTimeoutOpen, setSessionTimeoutOpen] = useState(false);
  const [changed, setChanged] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [isUncd, setIsUncd] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const timer = useRef(0);
  const { t, i18n } = useTranslation();
  const { instance } = useMsal();
  const analyticsLink = new AnalyticsLink();

  useMsalAuthentication(InteractionType.Redirect, loginRequest);
  const location = useLocation();

  const initLang = useCallback(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const currentLang = params.get('lang');

    if (currentLang) {
      window.sessionStorage.setItem('lang',currentLang);
      i18n.changeLanguage(currentLang);
    }
  }, [i18n]);

  const onLogoutForResetPassword = useCallback(() => {
    const logoutUrl = '/'
    const logoutRequest = {
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: logoutUrl
    }
    instance.logoutRedirect(logoutRequest);
  }, [instance]);


  const eventCallback = useCallback(() => {
    instance.addEventCallback((message: { eventType: EventType; interactionType: InteractionType; payload: {account: AccountInfo}; error: { errorMessage: string | string[]; }; }) => {
      if (message.payload?.account) {
        if (message.payload?.account?.idTokenClaims) {
          const tokenObj = message.payload?.account?.idTokenClaims;
          const str = 'isForgotPassword'
          const forgotPasswordFlag = tokenObj[str as keyof typeof tokenObj]
          if (typeof forgotPasswordFlag !== 'undefined') {
            setIsResetPassword(forgotPasswordFlag);
          }
        }
      }
  });
}, [instance]);

  useEffect(() => {
    initLang();

    let timeout = process.env.REACT_APP_SESSION_TIMEOUT || '';
    timer.current = window.setTimeout(() => {
        showSessionTimeoutDialog();},
        parseInt(timeout, 10));
    eventCallback();
    if(instance.getActiveAccount()?.idTokenClaims) {
      const tokenObj = instance.getActiveAccount()?.idTokenClaims!;
      const str = 'isForgotPassword'
      const forgotPasswordFlag = tokenObj[str as keyof typeof tokenObj];
      if (!isResetPassword && forgotPasswordFlag) {
        onLogoutForResetPassword();
      }
    }

    const resetTimeoutEvent = () => {
      if (!sessionTimeoutOpen) {
        clearTimeout(timer.current);
        timer.current = window.setTimeout(() => {
          showSessionTimeoutDialog();},
          parseInt(timeout, 10));
      }
    }

    document.addEventListener('click', resetTimeoutEvent);
    document.addEventListener('mousedown', resetTimeoutEvent);
    document.addEventListener('wheel', resetTimeoutEvent);
    document.addEventListener('touchstart', resetTimeoutEvent);
    document.addEventListener('touchmove', resetTimeoutEvent);
    document.addEventListener('keydown', resetTimeoutEvent);
    document.addEventListener('mousemove', resetTimeoutEvent);

    return () => {
      clearTimeout(timer.current);
      document.removeEventListener('keydown', resetTimeoutEvent);
      document.removeEventListener('mousedown', resetTimeoutEvent);
      document.removeEventListener('wheel', resetTimeoutEvent);
      document.removeEventListener('touchstart', resetTimeoutEvent);
      document.removeEventListener('touchmove', resetTimeoutEvent);
      document.removeEventListener('mousemove', resetTimeoutEvent);
      document.removeEventListener('click', resetTimeoutEvent);
    }
  }, [sessionTimeoutOpen, initLang, location.pathname, instance, eventCallback, isResetPassword, onLogoutForResetPassword])

  const handleChange = () => {
    setChanged(true);
  }

  const handleConfirmaton = () => {
    setConfirmation(true);
  }

  const showSessionTimeoutDialog = () => {
    setSessionTimeoutOpen(true);
  }

  const closeSessionTimeoutDialog = (event: React.MouseEvent) => {
    event.preventDefault();
    setSessionTimeoutOpen(false);
    clearTimeout(timer.current);
  }

  const onLanguageChange = (lang: string) =>{
    window.sessionStorage.setItem('lang',lang);
    document.documentElement.lang=lang;
    i18n.changeLanguage(lang)

    // sample code to dynamically change footer language, acceptable lang is either fr or en
    var footerEle = document.getElementById("footerElement");
    footerEle?.setAttribute("lang", lang);  
  }

  const onLogout = () => {
    if (changed && !confirmation) {
      showNavigationAwayDialog();
    } else {
      completeLogout();
    }
  }

  const completeLogout = () => {
    analyticsLink.sendLinkAnalyticsReport(LinkKey.abandonment, i18n.resolvedLanguage);
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }

  const showNavigationAwayDialog = () => {
    setNavigationOpen(true);
  }

  const closeNavigationAwayDialog = (event: React.MouseEvent) => {
    event.preventDefault();
    setNavigationOpen(false);
  }

  const onValidated = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onPrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isOnMobileBrowser = () => {
    return /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    );
  }

  const swithUncdAndEdobHandler = (isUncd: boolean) => {
    setIsUncd(isUncd);
  }

  const skipLink = (event: React.MouseEvent) => {
    event.preventDefault();
    const nodes = document.getElementsByClassName(
      'homediv',
    ) as HTMLCollectionOf<HTMLElement>;
    if (nodes.length > 0) {
        nodes[0].focus();
    }
  };

  const keyDownHandler = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.code === "Enter") {
      const nodes = document.getElementsByClassName(
        'homediv',
      ) as HTMLCollectionOf<HTMLElement>;
      if (nodes.length > 0) {
          nodes[0].focus();
      }
      return false;
    }
  };
  
  return (
    <ThemeProvider theme={wsibTheme}>
      <AuthenticatedTemplate>
        <StateProvider>
          <button className="link skipToMainConetent" id="appComponentSkipToMainContent" tabIndex={0} onKeyPress={keyDownHandler} onClick={skipLink}>
            {t('skipToMainContent')}
          </button>
          <SessionExpiredDialog
            isOpen={sessionTimeoutOpen} handleClose={closeSessionTimeoutDialog}
          >
          </SessionExpiredDialog>
          <NavigationAwayDialog
            isOpen={navigationOpen}
            handleClose={closeNavigationAwayDialog}
            handleLogout={completeLogout}
          >
          </NavigationAwayDialog>
          <div className="logo-table" role="banner">
            <div className="logoWrap">
              <div className="toggle" >
                {i18n.resolvedLanguage === 'en' && <button id="langToggleLink" className="headernav bumpRS linkActive" lang="fr" onClick={() => onLanguageChange('fr')}>Français</button>}
                {i18n.resolvedLanguage === 'fr' && <button id="langToggleLink" className="headernav bumpRS linkActive" lang="en" onClick={() => onLanguageChange('en')}>English</button>}
                <button className="headernav" onClick={onLogout}>{t('bt.exit')}</button>
              </div>
              <div className="logo-div">
                <a href={t('wsib.website.url')} target="_blank"  title="Home" rel="noopener noreferrer" className="logoLink">WSIB Ontario</a><span>{t('app.banner')}</span>
              </div>
            </div>
          </div>
          <div className="homediv" role="main" tabIndex={-1}>
            {isResetPassword &&  <ResetPassword onLogout={onLogoutForResetPassword}></ResetPassword>}
            {!isResetPassword && <Stepper activeStep={activeStep} sx={{ pb: 3 }} className={!isOnMobileBrowser() ? 'pagestep' : ''}>
              <Step key="get-started">
                {isUncd && <StepLabel aria-label={t("getStartUncdStepLabel")}>{!isOnMobileBrowser() && '1.' + t('stepper.get-started')}</StepLabel>}
                {!isUncd && <StepLabel aria-label={t("getStartEdobStepLabel")}>{!isOnMobileBrowser() && '1.' + t('stepper.get-started')}</StepLabel>}
              </Step>
              <Step key="claimant-info">
                {isUncd && <StepLabel aria-label={t("addUncdStepLabel")}>{!isOnMobileBrowser() && '2.' + t('stepper.claimant-info')}</StepLabel>}
                {!isUncd && <StepLabel aria-label={t("addEdobStepLabel")}>{!isOnMobileBrowser() && '2.' + t('stepper.claimant-info')}</StepLabel>}
              </Step>
              <Step key="document-upload">
                {isUncd && <StepLabel aria-label={t("uncdUploadStepLabel")}>{!isOnMobileBrowser() && '3.' + t('stepper.document-upload')}</StepLabel>}
                {!isUncd && <StepLabel aria-label={t("edobUploadStepLabel")}>{!isOnMobileBrowser() && '3.' + t('stepper.document-submit')}</StepLabel>}
              </Step>
              {isUncd && <Step key="support-document">
                <StepLabel aria-label={t("supportUploadStepLabel")}>{!isOnMobileBrowser() && '4.' + t('stepper.support-document')}</StepLabel>
              </Step>}
              <Step key="review">
                {isUncd && <StepLabel aria-label={t("uncdReviewStepLabel")}>{!isOnMobileBrowser() && '5.' + t('stepper.review')}</StepLabel>}
                {!isUncd && <StepLabel aria-label={t("edobReviewStepLabel")}>{!isOnMobileBrowser() && '4.' + t('stepper.review')}</StepLabel>}
              </Step>
              <Step key="confirmation">
                {isUncd && <StepLabel aria-label={t("uncdCofirmStepLabel")}>{!isOnMobileBrowser() && '6.' + t('stepper.confirmation')}</StepLabel>}
                {!isUncd && <StepLabel aria-label={t("edobConfirmStepLabel")}>{!isOnMobileBrowser() && '5.' + t('stepper.confirmation')}</StepLabel>}
              </Step>
            </Stepper>}
            {!isResetPassword && <div className="container">
              {activeStep === 0 && <div><Getstarted onValidated={onValidated} onSwitchUncdAndEdob={swithUncdAndEdobHandler}></Getstarted></div>}
              {activeStep === 1 && <div><ClaimantInfo onValidated={onValidated} onPrev={onPrev} onChanged={handleChange} ></ClaimantInfo></div>}
              {activeStep === 2 && <div><DocUpload onValidated={onValidated} onPrev={onPrev}></DocUpload></div>}
              {isUncd && activeStep === 3 && <div><SupportDocUpload onValidated={onValidated} onPrev={onPrev}></SupportDocUpload></div>}
              {!isUncd && activeStep === 3 && <div><Review onValidated={onValidated} onPrev={onPrev}></Review></div>}
              {isUncd && activeStep === 4 && <div><Review onValidated={onValidated} onPrev={onPrev}></Review></div>}
              {!isUncd && activeStep === 4 && <div><Confirmation onValidated={onValidated} onPrev={onPrev} onConfirmaton={handleConfirmaton}></Confirmation></div>}
              {isUncd && activeStep === 5 && <div><Confirmation onValidated={onValidated} onPrev={onPrev} onConfirmaton={handleConfirmaton}></Confirmation></div>}
            </div>}
          </div>
        </StateProvider>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default App;
