// this implementation is based on angular ng2-file-upload npm package at 
// https://github.com/valor-software/ng2-file-upload/tree/development/libs/ng2-file-upload/file-upload

import { ParsedResponseHeaders } from "../Helper";
import { Categories } from "./Categories";
import { EdobForms } from "./EdobForms";
import { Forms } from "./Forms";

export type FileItemCallback = {
    onBeforeUpload: (item: FileItem) => void,
    onProgress: (item: FileItem, progress: number) => any,
    onSuccess: (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => any,
    onError: (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => any,
    onCancel: (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => any,
    onComplete: (item:FileItem, response: string, status: number, headers: ParsedResponseHeaders) => any
};

export class FileItem {

    public _file: File;
    public alias: string;
    public formData: any = [];
    public isUploading: boolean = false;
    public isUploaded: boolean = false;
    public isSuccess: boolean = false;
    public isCancel: boolean = false;
    public isError: boolean = false;
    public isWsibForm: string = "";
    public isWsibTouched: boolean = false;
    public progress: number = 0;
    public _xhr: XMLHttpRequest | null;
    public wsibForm: Forms | null;
    public edobForm: EdobForms | null;
    public subEdobForm: EdobForms | null;
    public category: Categories | null;
    public wsibFormList: EdobForms[] = [];
    public noWsibFormList: EdobForms[] = [];
    public headers: {name: string, value: string}[];
    public isCategoryOpen: boolean = false;
    public isSubDocumentOpen: boolean = false;
    public categoryList: Categories[] | [];
    public claimId: string;
    public lastName: string;
    public dateOfBirth: string;

    constructor(file: File, callback: FileItemCallback) {
        this.formData = [];
        this.isUploading = false;
        this.isUploaded = false;
        this.isSuccess = false;
        this.isCancel = false;
        this.isError = false;
        this.isWsibForm = "";
        this.isWsibTouched = false;
        this.progress = 0;
        this.wsibForm = null;
        this.edobForm = null;
        this._file = file;
        this.alias = 'file';
        this._xhr = null;
        this.headers = [];
        this.category = null;
        this.wsibFormList = [];
        this.subEdobForm = null;
        this.noWsibFormList = [];
        this.isCategoryOpen = false;
        this.isSubDocumentOpen = false;
        this.categoryList = [];
        this.claimId = '';
        this.lastName = '';
        this.dateOfBirth = '';

        if (callback) {
            if (callback.onBeforeUpload) {
                this.onBeforeUpload = callback.onBeforeUpload;
            }

            if (callback.onProgress) {
                this.onProgress = callback.onProgress;
            }
            if (callback.onSuccess) {
                this.onSuccess = callback.onSuccess;
            }
            if (callback.onError) {
                this.onError = callback.onError;
            }
            if (callback.onCancel) {
                this.onCancel = callback.onCancel;
            }
            if (callback.onComplete) {
                this.onComplete = callback.onComplete;
            }
        }
    }

    cancel() {
        this._xhr?.abort();
    }

    onBeforeUpload(item: FileItem): void {
        return void 0;
    }

    onProgress(item: FileItem, progress: number): any {
        return { item, progress };
    }

    onSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        return { item, response, status, headers };
    }

    onError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        return { item, response, status, headers };
    }
  
    onCancel(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
        return { item, response, status, headers };
    }

    onComplete(item:FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
        return { item, response, status, headers };
    }

    async _onBeforeUpload() {
        this.isUploading = true;
        this.isUploaded = false;
        this.isSuccess = false;
        this.isCancel = false;
        this.isError = false;
        this.progress = 0;
        return this.onBeforeUpload(this);
    }

    _onProgress(progress: number) {
        this.progress = progress;
        this.onProgress(this, progress);
    }

    _onSuccess(response: string, status: number, headers: ParsedResponseHeaders) {
        this.isUploading = false;
        this.isUploaded = true;
        this.isSuccess = true;
        this.isCancel = false;
        this.isError = false;
        this.progress = 100;
        this.onSuccess(this, response, status, headers);
    }

    _onError(response: string, status: number, headers: ParsedResponseHeaders) {
        this.isUploading = false;
        this.isUploaded = true;
        this.isSuccess = false;
        this.isCancel = false;
        this.isError = true;
        this.progress = 0;
        this.onError(this, response, status, headers);
    }

    _onCancel(response: string, status: number, headers: ParsedResponseHeaders) {
        this.isUploading = false;
        this.isUploaded = false;
        this.isSuccess = false;
        this.isCancel = true;
        this.isError = false;
        this.progress = 0;
        this.onCancel(this, response, status, headers);
    }

    _onComplete(response: string, status: number, headers: ParsedResponseHeaders) {
        this.isUploading = false;
        this.onComplete(this, response, status, headers);
    }
 
}

export default FileItem ;